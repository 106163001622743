import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useTranslation } from 'react-i18next';

const FooterContainer = styled.footer`
  background: linear-gradient(to right, ${props => props.theme.colors.dark}, ${props => props.theme.colors.primary});
  color: rgba(255, 255, 255, 0.9);
  padding: 5rem 2rem 2rem;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(to right, ${props => props.theme.colors.accent}, ${props => props.theme.colors.secondary});
  }
`;

const FooterContent = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1.5fr;
  gap: 4rem;

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const FooterSection = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FooterTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: white;
  position: relative;
  padding-bottom: 0.5rem;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 2px;
    background: ${props => props.theme.colors.accent};
  }
`;

const CompanyDescription = styled.p`
  line-height: 1.8;
  opacity: 1;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.95);
  font-size: 1rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const FooterLink = styled(Link)`
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    color: ${props => props.theme.colors.accent};
    transform: translateX(5px);
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  color: rgba(255, 255, 255, 0.9);

  svg {
    color: ${props => props.theme.colors.accent};
  }
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  font-size: 0.95rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  p {
    color: white;
    margin-bottom: 0.5rem;

    &:last-child {
      font-size: 0.85rem;
      opacity: 0.9;
    }
  }

  a {
    color: white;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      color: ${props => props.theme.colors.accent};
    }
  }
`;

const StoreLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: ${props => props.theme.colors.accent};
  color: white;
  padding: 1rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: ${props => props.theme.fontWeights.medium};
  transition: all 0.3s ease;
  margin-top: 1.5rem;

  &:hover {
    background: ${props => props.theme.colors.secondary};
    transform: translateY(-2px);
  }

  .icon {
    font-size: 1.5rem;
  }
`;

const FriendLinks = styled.div`
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LinkTitle = styled.span`
  color: white;
  font-weight: ${props => props.theme.fontWeights.medium};
  margin-right: 1rem;
`;

const LinkItem = styled.a`
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 0.95rem;
  padding: 0.2rem 0.6rem;
  border-radius: 4px;

  &:hover {
    color: ${props => props.theme.colors.accent};
    background: rgba(255, 255, 255, 0.1);
  }
`;

const friendlyLinks = [
  {
    key: 'hipal',
    url: "https://hipal.club"
  },
  {
    key: 'chenyuan',
    url: "https://cy-hose.com/"
  },
  {
    key: 'guokan',
    url: "https://guokangroup.com/"
  }
];

function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
        >
          <FooterTitle>{t('footer.about.title')}</FooterTitle>
          <CompanyDescription>
            {t('footer.about.description')}
          </CompanyDescription>
          <StoreLink 
            href="https://nbyxgy.1688.com/" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <StorefrontIcon className="icon" />
            {t('footer.about.mall')}
          </StoreLink>
        </FooterSection>

        <FooterSection
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.1 }}
          viewport={{ once: true }}
        >
          <FooterTitle>{t('footer.quickLinks.title')}</FooterTitle>
          <FooterLink to="/products">{t('footer.quickLinks.products')}</FooterLink>
          <FooterLink to="/about">{t('footer.quickLinks.about')}</FooterLink>
          <FooterLink to="/contact">{t('footer.quickLinks.contact')}</FooterLink>
        </FooterSection>

        <FooterSection
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          viewport={{ once: true }}
        >
          <FooterTitle>{t('footer.categories.title')}</FooterTitle>
          <FooterLink 
            to="/products" 
            onClick={() => {
              window.scrollTo(0, 0);
              setTimeout(() => {
                const categoryButtons = document.querySelectorAll('button[data-category]');
                const gasButton = Array.from(categoryButtons).find(btn => btn.dataset.category === 'gas');
                if (gasButton) gasButton.click();
              }, 100);
            }}
          >
            {t('footer.categories.gas')}
          </FooterLink>
          <FooterLink 
            to="/products"
            onClick={() => {
              window.scrollTo(0, 0);
              setTimeout(() => {
                const categoryButtons = document.querySelectorAll('button[data-category]');
                const corrugatedButton = Array.from(categoryButtons).find(btn => btn.dataset.category === 'corrugated');
                if (corrugatedButton) corrugatedButton.click();
              }, 100);
            }}
          >
            {t('footer.categories.corrugated')}
          </FooterLink>
          <FooterLink 
            to="/products"
            onClick={() => {
              window.scrollTo(0, 0);
              setTimeout(() => {
                const categoryButtons = document.querySelectorAll('button[data-category]');
                const accessoriesButton = Array.from(categoryButtons).find(btn => btn.dataset.category === 'accessories');
                if (accessoriesButton) accessoriesButton.click();
              }, 100);
            }}
          >
            {t('footer.categories.accessories')}
          </FooterLink>
        </FooterSection>

        <FooterSection>
          <FooterTitle>{t('footer.contact.title')}</FooterTitle>
          <ContactInfo>
            <ContactItem>
              <LocationOnIcon />
              <span>{t('footer.contact.address')}</span>
            </ContactItem>
            <ContactItem>
              <PhoneIcon />
              <span>{t('footer.contact.phone')}</span>
            </ContactItem>
            <ContactItem>
              <EmailIcon />
              <span>{t('footer.contact.email')}</span>
            </ContactItem>
            <ContactItem>
              <AccessTimeIcon />
              <div>
                <div>{t('footer.contact.hours.weekdays')}</div>
                <div>{t('footer.contact.hours.weekend')}</div>
              </div>
            </ContactItem>
          </ContactInfo>
        </FooterSection>
      </FooterContent>

      <FriendLinks>
        <LinkTitle>{t('footer.friendlyLinks.title')}：</LinkTitle>
        {friendlyLinks.map((link, index) => (
          <LinkItem 
            key={index}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t(`footer.friendlyLinks.links.${link.key}`)}
          </LinkItem>
        ))}
      </FriendLinks>

      <Copyright>
        <p>{t('footer.copyright.rights').replace('{year}', currentYear)}</p>
        <p>
          <a 
            href="https://beian.miit.gov.cn/" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            {t('footer.copyright.icp')}
          </a>
        </p>
        <p>
          <a 
            href="https://heg-tech.xin/" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            技术支持：核光科技
          </a>
        </p>
      </Copyright>
    </FooterContainer>
  );
}

export default Footer; 